import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import Servizio from '../../components/Servizio'

import NationalExpressImg from '../../images/national_express.jpg'

const paginaServizio = {
  titolo: 'National Express',
  parent: null,
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: NationalExpressImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'La nostra grande flessibilità ci consente di spedire fino a 500 kg e di venire sempre incontro alle tue esigenze, offrendoti soluzioni su misura, studiate ad hoc per qualsiasi spedizione.',
        },
        {
          id: 2,
          testo:
            'È possibile personalizzare tempi, destinazioni e modalità di consegna. Per noi spedizioni dedicate, consegne concordate o ritiri indiretti non sono un problema.',
        },
        {
          id: 3,
          testo:
            'Il servizio viene espletato in conformità a quanto previsto dalle Condizioni generali di contratto di trasporto sottoscritte da ciascun cliente all’atto della firma del contratto stipulato con la Sede GLS di competenza.',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per spedizione',
          valore: '500 kg',
        },
        {
          id: 2,
          descrizione: 'Misura massima',
          valore: '300 cm',
        },
        {
          id: 3,
          descrizione: 'Peso massimo per collo',
          valore: '70 kg',
        },
        {
          id: 4,
          descrizione: 'Dimensioni massime per Pallet',
          valore: '200 cm (altezza)\n150 cm (larghezza)\n130 cm (lunghezza)',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo: 'Servizio espresso di consegna door-to-door',
        },
        {
          id: 2,
          testo:
            'Tempi di consegna regolari: 24 ore nella penisola; 48 ore in Sicilia, Calabria e Sardegna',
        },
        {
          id: 3,
          testo: 'Spedizioni multi-collo',
        },
        {
          id: 4,
          testo:
            'Tracking & Tracing costante con informazioni complete sullo stato della spedizione',
        },
        {
          id: 5,
          testo: 'Prova di consegna disponibile on line',
        },
        {
          id: 6,
          testo:
            'Prenotazione della spedizione tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: null,
    },
  ],
}

const NationalExpress = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default NationalExpress
